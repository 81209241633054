import DocumentTranslationService from "components/DocumentTranslationService";
import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";

const index = () => {
  return (
    <Layout>
      <SEO
        title="Legal Document Translation Services - Secure and Fast"
        description="Translate any legal document with expert linguists. Work with legal translators in your specific field and jurisdiction. Order online 24/7."
      />
      <DocumentTranslationService />
    </Layout>
  );
};

export default index;

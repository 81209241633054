import FirstAdComp from "components/homepage/Ads-Page/firstAdComp";
import SecondAdComp from "components/homepage/Ads-Page/secondAdComp";
import React from "react";
import OurClients from "components/LP/ourClients";
import ThirdComp from "components/LP/thirdComp";
import { LegalData } from "../homepage/Ads-Page/config";
import AdContact from "components/homepage/Ads-Page/AdContact";
import SeventhAdComp from "components/homepage/websiteTranslation/seventhAdComp";
import About from "components/LP/About";
import Industries from "components/LP/Industries";
import RelativeLanguages from "components/LP/RelativeLanguages";
import Services from "components/LP/Services";
import { ServicesData } from "components/LP/data";
import ReviewSection from "components/LP/review";
import AdsCarousel from "components/homepage/Ads-Page/carosule";
import { useState } from "react";
import { LegalDocData, LegalDocData2 } from "components/LP/data";
import { LegalSecondNewData } from "../homepage/Ads-Page/config";
import { LegalDocumentData } from "components/LP/data";

const DocumentTranslationService = () => {
  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [open, setOpen] = useState(false);

  if (typeof window !== "undefined") {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }

  return (
    <>
      <FirstAdComp
        heading="Quick and Reliable Legal Translation Services"
        content="We provide legal translation services that meet every expectation of quality and confidentiality. Here at Tomedes, your legal documents will be in the hands of dedicated linguists who understand not only the language of law, but also the high standard of conduct that comes with the profession. We offer quick delivery times and are available 24/7, with highly competitive rates to meet your budget. Trusted by law firms in New York, Chicago, California, Canada, the United Kingdom, and across the world."
        FromTextColor="Get In Touch"
        FromText1="With"
        FromText2="Our Translation Company"
        fontSize="text-[16px] sm:text-[18px]"
        marginTop="sm:mt-[37px]"
        HeadfontSize="sm:text-[45px]"
        paddingY="sm:pb-10 sm:pt-0 pt-[2.5rem]"
        bottomService
        contentTwo="Providing clients with the optimal document translation solution is the heart of our service. Quick delivery, 24/7 service. Quality and expertise at competitive rates."
        hideContent
      />
      <OurClients />
      <ThirdComp
        firstHead=" Certified Legal"
        secondHead="Translation Services"
        thirdHead="With the Tomedes Guarantee"
        contentOne="We place a premium on ensuring that your legal document translations are held to the highest standard of accuracy and quality, and are fully compliant with the requirements of your target states or territories. Contracts, medical records, licenses, patents, diplomas—any legal document you need, we’re ready to translate and certify, with full guarantee that your translations will be accepted wherever you need them. USCIS approved."
        contentTwo=" "
      />
      <SecondAdComp
        midContent
        data={LegalData}
        parentDivwidth
        customWidth="max-w-[300px]"
        headTitle="Why Translate With Us?"
        fontBold
        title="Why Translate With Us?"
        gapX
        noPadding
        NotshowCarousel
        LegalTopCarousel
      />
      <AdContact
        open={open}
        setOpen={setOpen}
        head="Ready to get started?"
        paddingY="pt-14 sm:pb-10"
        mobileContactButton
      />
      <SeventhAdComp
        serviceSection
        content="Tomedes is recognized by the International Organization for Standards, the foremost authority on industry standards in the world. The company is ISO-certified in three categories relevant to translation services:"
        head="Legal Translation Services Done to ISO Standards"
        bottomHead="A Team of Expert In-House Legal Translators"
        bottomContent="Tomedes has built a robust and dedicated network of language professionals from all over the world. Our legal translators are experts at what they do, handling intricate legalese and law-specific terminology. We assign only native speakers of your target language to ensure not only the quality of the translations but also that they fit the legal requirements of your target locale. Each translator is selected and trained to maintain the highest standard of professional conduct, ensuring full confidentiality while working efficiently to deliver you the best results in the shortest time."
      />
      <RelativeLanguages
        head="Legal Translation Services in 120 Languages"
        open={open}
        setOpen={setOpen}
      />
      <Industries
        open={open}
        setOpen={setOpen}
        head="Legal Document Translation Services For Any Need"
        content="Not just any language professional will do here at Tomedes. We ensure that your translation project is handled by the right professionals with a strong background in law, with expertise in handling not just legal terminology but also the legal system of your target locale. Wherever necessary, our legal translations also come with an affidavit attesting that they are a true and accurate representation of the original document, in full compliance with legal requirements. Globally accepted and USCIS approved."
        contentThree="We provide a comprehensive suite of language solutions for the legal sector including:"
        DocumentData={LegalDocumentData}
      />
      <ReviewSection
        open={open}
        setOpen={setOpen}
        heading="A Wide Portfolio Of Legal Document Translation Solutions"
        contentTop="We at Tomedes pride ourselves on the versatility of our legal translations services offerings. Our translators have extensive experience translating different kinds of documents and material. These include:"
        data={LegalDocData}
        data2={LegalDocData2}
        cardsData={LegalSecondNewData}
        hideCarousel={true}
        showBottomCarousel={true}
      />
      <SecondAdComp noContent BottomSection NotshowCarousel />
      <About
        content="Ofer Tirosh founded Tomedes in 2007 with the goal of providing translation services for businesses looking to develop a strong global presence by taking root in different locales around the world. The company has since grown into a leading multinational corporation with clients from Asia, Europe, the United Kingdom, Canada, and the East and West coasts of the United States.
"
      />
    </>
  );
};

export default DocumentTranslationService;
